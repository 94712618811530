import axios from 'axios'

class SectionOtherApi {
  async get({ page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(`counting/otherSalary/section?page=${page}&limit=${limit}&search=${search}`)
    return response
  }

  async getAll() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get('counting/otherSalary/section/getAll')
    return response
  }

  async add({ name }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.post('counting/otherSalary/section', {
      name,
    })
    return response
  }

  async edit({ _id, name }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.put(`counting/otherSalary/section`, {
      _id,
      name,
    })
    return response
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.delete(`counting/otherSalary/section/section_id/${id}`)
    return response
  }
}

export default new SectionOtherApi()

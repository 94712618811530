import axios from 'axios'

class SectionOtherApi {
  async get({ page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(`counting/otherSalary/service?page=${page}&limit=${limit}&search=${search}`)
    return response
  }

  async getAllBySection(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(`counting/otherSalary/service/getAll/section_id/${id}`)
    return response
  }

  async add({ name, price, section }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.post('counting/otherSalary/service', {
      name,
      price,
      section,
    })
    return response
  }

  async edit({ _id, name, price, section }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.put(`counting/otherSalary/service`, {
      _id,
      name,
      price,
      section,
    })
    return response
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.delete(`counting/otherSalary/service/service_id/${id}`)
    return response
  }
}

export default new SectionOtherApi()
